<mat-tab-group mat-stretch-tabs="false">
  <mat-tab label="Associer à un ensemble">
    <ng-template matTabContent>
      <app-set-attribution (validate)="onValidateChoice($event)"
                           [selectedProject]="selectedProject"></app-set-attribution>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="!onlySelectEnsemble" label="Gestion des ensembles">
    <ng-template matTabContent>
      <app-set-management></app-set-management>
    </ng-template>
  </mat-tab>
</mat-tab-group>

