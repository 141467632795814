import {Component, OnDestroy} from '@angular/core';
import {AlertService} from '../../../../services/alert.service';
import {MatDialogRef} from '@angular/material/dialog';
import {SetManagementComponent} from './set-management/set-management.component';
import {NgIf} from '@angular/common';
import {SetAttributionComponent} from './set-attribution/set-attribution.component';
import {MatTabsModule} from '@angular/material/tabs';

@Component({
  selector: 'app-dialog-add-ensemble',
  templateUrl: './dialog-add-ensemble.component.html',
  styleUrls: ['./dialog-add-ensemble.component.scss'],
  standalone: true,
  imports: [MatTabsModule, SetAttributionComponent, NgIf, SetManagementComponent]
})
export class DialogAddEnsembleComponent implements OnDestroy {
  result = false;
  selectedProject: number;
  onlySelectEnsemble = false;

  constructor(
    public readonly dialogRef: MatDialogRef<DialogAddEnsembleComponent>,
    private readonly alertService: AlertService
  ) {}

  onValidateChoice(value) {
    this.dialogRef.close(value);
  }

  ngOnDestroy() {
    this.alertService.clear();
  }
}
