import {Component, OnInit} from '@angular/core';
import {forkJoin} from 'rxjs';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {TreeItemNode} from 'src/app/model/event/treeData/tree-item-node.model';
import {TreeDataService} from 'src/app/services/tree-data.service';
import {AlertService} from 'src/app/services/alert.service';
import {Router} from '@angular/router';
import {SelectOption} from 'src/app/model/form/select-option.model';
import {ParamWebservice} from 'src/app/services/webservices/param.webservice';
import {Ensemble} from 'src/app/model/event/ensemble.model';
import {EnsembleWebservice} from 'src/app/services/webservices/ensemble.webservice';
import {PaginationService} from 'src/app/services/pagination.service';
import {DateAdapter, MatOptionModule} from '@angular/material/core';
import {CustomDateAdapter} from 'src/app/utils/custom-date-adapter';
import {Destroyed} from '../../../directives/destroyed.directive';
import {PThematique} from 'src/app/model/param/p-thematique.model';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {PaginationComponent} from '../../../pagination/pagination.component';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {TreeSettingComponent} from '../../../form-parts/form-items/tree-setting/tree-setting.component';
import {MatButtonModule} from '@angular/material/button';
import {Paged} from 'src/app/model/response/paged.model';

@Component({
  selector: 'app-set-management',
  templateUrl: './set-management.component.html',
  styleUrls: ['./set-management.component.scss'],
  providers: [PaginationService, {provide: DateAdapter, useClass: CustomDateAdapter}],
  standalone: true,
  imports: [
    TreeSettingComponent,
    NgIf,
    PaginationComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    NgClass,
    MatDatepickerModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class SetManagementComponent extends Destroyed implements OnInit {
  edit = false;
  showForm = false;
  ensembleForm = this.initNewEnsembleForm();
  thematiqueList: SelectOption[] = [];
  isLoading = true;
  minDate: Date = null;
  maxDate: Date = null;

  itemPerPage = 10;

  constructor(
    private readonly ensembleWebService: EnsembleWebservice,
    private readonly paginationService: PaginationService,
    private readonly paramService: ParamWebservice,
    private readonly treeDataService: TreeDataService,
    private readonly alertService: AlertService,
    private readonly router: Router
  ) {
    super();
  }

  get label() {
    return this.ensembleForm.get('label').value;
  }

  get dateBeginValue() {
    return this.ensembleForm.get('dateBegin').value;
  }

  get dateEndValue() {
    return this.ensembleForm.get('dateEnd').value;
  }

  ngOnInit() {
    this.paginationService.initPagination(this.itemPerPage);

    this.ensembleWebService
      .getEnsembleTree(this.paginationService.pageRequest, true)
      .pipe(this.untilDestroyed())
      .pipe(this.untilDestroyed())
      .subscribe({
        next: (data: Paged<TreeItemNode>) => {
          data.items.unshift(this.addCreateEnsembleButton());
          this.treeDataService.changeAllEnsemble(data.items);
          this.initNewEnsembleForm();
          this.updatePagination(data);
        }
      })
      .add(() => {
        this.isLoading = false;
      });

    this.initPThematique();
  }

  updateList() {
    this.ensembleWebService
      .getEnsembleTree(this.paginationService.pageRequest, true)
      .pipe(this.untilDestroyed())
      .pipe(this.untilDestroyed())
      .subscribe((data: Paged<TreeItemNode>) => {
        data.items.unshift(this.addCreateEnsembleButton());
        this.treeDataService.changeAllEnsemble(data.items);
      });
  }

  public addCreateEnsembleButton() {
    return {
      id: 0,
      name: 'Créer un projet'
    };
  }

  public initNewEnsembleForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(''),
      label: new UntypedFormControl('', Validators.required),
      dateBegin: new UntypedFormControl('', Validators.required),
      dateEnd: new UntypedFormControl(''),
      level: new UntypedFormControl('', Validators.required),
      idPThematique: new UntypedFormControl('', Validators.required),
      idParent: new UntypedFormControl('')
    });
  }

  compare(c1: number, c2: number): boolean {
    return c1 === c2;
  }

  public addEnsemble(node) {
    this.edit = false;
    this.showForm = true;
    this.ensembleForm = this.initNewEnsembleForm();
    this.ensembleForm.get('level').setValue(1);
    this.initPThematique(3);
    this.minDate = null;
    this.maxDate = null;
    if (node.id !== 0) {
      this.ensembleWebService
        .getEnsembleById(node.id)
        .pipe(this.untilDestroyed())
        .subscribe((res) => {
          const result = res as Ensemble;
          this.ensembleForm.get('idParent').setValue(node.id);
          this.ensembleForm.get('level').setValue(node.niveau + 1);
          this.minDate = result.dateBegin;
          this.maxDate = result.dateEnd;
          this.initPThematique(2);
        });
    }
  }

  public deleteEnsemble(node) {
    let errorMessage = '';
    let checkChilds = false;
    let checkLink = false;

    const haveLinkedEvent = this.ensembleWebService.ensembleHaveLinkEventById(node.id);
    const haveSubProject = this.ensembleWebService.ensembleHaveChildById(node.id);

    forkJoin([haveLinkedEvent, haveSubProject])
      .pipe(this.untilDestroyed())
      .subscribe((results: [boolean, boolean]) => {
        checkLink = results[0];
        checkChilds = results[1];

        if (!checkChilds && !checkLink) {
          this.ensembleWebService
            .deleteEnsembleById(node.id, this.paginationService.pageRequest)
            .pipe(this.untilDestroyed())
            .pipe(this.untilDestroyed())
            .subscribe((res) => {
              this.updatePaginationAction(res, this.paginationService.getPage());
              res.items.unshift(this.addCreateEnsembleButton());
              this.treeDataService.changeAllEnsemble(res.items);
            });
        } else {
          errorMessage =
            'Impossible de mettre à jour. Ce projet est relié à un ou plusieurs événements ou possède des sous-projets.';
          this.alertService.error(errorMessage);
        }
        this.minDate = null;
        this.maxDate = null;
      });
  }

  public editEnsemble(node) {
    this.initPThematique();
    this.edit = true;
    let errorMessage = '';
    let checkChilds = false;
    let checkLink = false;
    this.ensembleWebService
      .getEnsembleById(node.id)
      .pipe(this.untilDestroyed())
      .subscribe((ens: Ensemble) => {
        const ensemble = ens;
        // Si c'est un niveau 2, on affiche
        if (ensemble.level === 2) {
          this.ensembleWebService
            .getEnsembleById(ensemble.idParent)
            .pipe(this.untilDestroyed())
            .subscribe((value) => {
              const result = value as Ensemble;
              this.minDate = result.dateBegin;
              this.maxDate = result.dateEnd;
            });
          this.thematiqueList = this.thematiqueList.filter((item) => item.level === 2);
          this.showForm = true;
        }

        // Si c'est un niveau 1, on veut vérifier s'il a des evenements ou des sous-projets
        if (ensemble.level === 1) {
          this.maxDate = null;
          this.minDate = null;
          this.thematiqueList = this.thematiqueList.filter(
            (item) => item.level === undefined || item.level === 1
          );

          const haveLinkedEvent = this.ensembleWebService.ensembleHaveLinkEventById(ensemble.id);
          const haveSubProject = this.ensembleWebService.ensembleHaveChildById(ensemble.id);

          forkJoin([haveLinkedEvent, haveSubProject])
            .pipe(this.untilDestroyed())
            .subscribe((results: [boolean, boolean]) => {
              checkLink = results[0];
              checkChilds = results[1];

              if (!checkChilds && !checkLink) {
                this.showForm = true;
              } else {
                this.showForm = false;
                errorMessage =
                  'Impossible de mettre à jour. Ce projet est relié à un ou plusieurs événements ou possède des sous-projets.';
                this.alertService.error(errorMessage);
              }
              this.minDate = null;
              this.maxDate = null;
            });
        }

        // On fill le form si tout est good
        this.ensembleForm.patchValue({
          id: ensemble.id,
          label: ensemble.label,
          dateBegin: ensemble.dateBegin,
          dateEnd: ensemble.dateEnd,
          idParent: ensemble.idParent,
          level: ensemble.level,
          idPThematique: ensemble.idPThematique
        });
      });
  }

  saveEnsemble() {
    const ensemble = this.ensembleForm.value as Ensemble;
    if (this.ensembleForm.valid) {
      if (this.edit) {
        this.ensembleWebService
          .editEnsemble(ensemble, this.paginationService.pageRequest)
          .pipe(this.untilDestroyed())
          .pipe(this.untilDestroyed())
          .subscribe((res) => {
            this.updatePaginationAction(res, this.paginationService.getPage());
            res.items.unshift(this.addCreateEnsembleButton());
            this.treeDataService.changeAllEnsemble(res.items);
            this.showForm = false;
            this.ensembleForm = this.initNewEnsembleForm();
          });
      } else {
        this.ensembleWebService
          .createEnsemble(ensemble, this.paginationService.pageRequest)
          .pipe(this.untilDestroyed())
          .pipe(this.untilDestroyed())
          .subscribe((res) => {
            this.updatePaginationAction(res, this.paginationService.getPage());
            res.items.unshift(this.addCreateEnsembleButton());
            this.treeDataService.changeAllEnsemble(res.items);
            this.showForm = false;
            this.ensembleForm = this.initNewEnsembleForm();
          });
      }
    } else {
      this.alertService.error('Il manque des informations');
    }
  }

  detailEnsemble(id: number) {
    this.router.navigate(['Ensemble-detail', id]);
  }

  updatePagination(res: Paged<TreeItemNode>) {
    this.paginationService.setPagination(res.count);
  }

  updatePaginationAction(res: Paged<TreeItemNode>, currentPage: number) {
    this.paginationService.setPage(currentPage);
    this.paginationService.setPageMax(res.count >= 10 ? Math.ceil(res.count / 10) : 1);
    this.paginationService.setCount(res.count);
    this.paginationService.setMinElem(this.paginationService.getMinElem());
    if (this.paginationService.getCount() > 10) {
      this.paginationService.setMaxElem(
        this.paginationService.getMinElem() + (res.items.length - 1)
      );
    } else {
      this.paginationService.setMaxElem(this.paginationService.getCount());
    }
  }

  closeAddPanel() {
    this.showForm = false;
  }

  private initPThematique(value?) {
    this.thematiqueList = [];
    this.paramService
      .getAllPThematique()
      .pipe(this.untilDestroyed())
      .subscribe({
        next: (content: PThematique[]) => {
          content.forEach((thematique) => {
            const option = new SelectOption();
            option.id = thematique.id;
            option.name = thematique.label;
            option.level = thematique.level;
            this.thematiqueList.push(option);
          });
        },
        complete: () => {
          if (value === 2) {
            this.thematiqueList = this.thematiqueList.filter((item) => item.level === 2);
          }
          if (value === 3) {
            this.thematiqueList = this.thematiqueList.filter(
              (item) => item.level === undefined || item.level === 1
            );
            if (this.thematiqueList.length === 1) {
              this.ensembleForm.get('idPThematique').setValue(this.thematiqueList[0].id);
            }
          }
        }
      });
  }
}
