<div class="d-flex flex-row">
  <div class="h-100 w-40"></div>
  <div class="bar"></div>
  <div class="h-100 w-40"></div>
</div>

<div class="d-flex col-12 h-100">
  <div class="row h-100" [ngClass]="showForm ? 'col-6' : 'col-12'">
    <div class="h-80 w-100" style="overflow-y: scroll">
      <app-tree-setting (createClicked)="addEnsemble($event)" (deleteClicked)="deleteEnsemble($event)"
                        (editClicked)="editEnsemble($event)" [isEnsembleSetting]="true"></app-tree-setting>

    </div>
    <app-pagination (pageChange)="updateList()" *ngIf="!isLoading" [itemPerPage]="10"></app-pagination>
  </div>
  <div class="bar" *ngIf="showForm"></div>
  <div class="col-6 " *ngIf="showForm">
    <div class="h-100">
      <button mat-button (click)="closeAddPanel()">Fermer</button>
      <form [formGroup]="ensembleForm">
        <div class="row">
          <div class="d-flex flex-column align-items-start col-md-6 col-sm-4">
            <label>Nom</label>
            <input
              formControlName="label"
              class="form-control"
              type="search">
            <mat-error *ngIf="ensembleForm.get('label').dirty && ensembleForm.get('label').hasError('required')">
              Le nom est <strong>obligatoire</strong>
            </mat-error>
          </div>
        </div>

        <div class="row">
          <div class="d-flex flex-column align-items-start col-md-6 col-sm-4">
            <label>Thématique</label>
            <mat-select class="form-control" [compareWith]="compare" [formGroup]="ensembleForm"
                        formControlName="idPThematique">
              <mat-option *ngFor="let item of thematiqueList" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="ensembleForm.get('idPThematique').dirty && ensembleForm.get('idPThematique').hasError('required')">
              La thématique est <strong>obligatoire</strong>
            </mat-error>
          </div>
        </div>


        <div class="d-flex flex-column my-2 align-items-start">
          <label>Date de début et fin</label>
          <div class="d-flex flex-row" [ngClass]="{badDateInput : ensembleForm.errors?.dateFormat}">
            <div class="input-group">
              <input [matDatepicker]="startPicker"
                     autocomplete="off"
                     class="form-control"
                     [max]="maxDate"
                     [min]="minDate"
                     [formGroup]="ensembleForm"
                     formControlName="dateBegin">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary form-control" (click)="startPicker.open()">
                  <mat-icon>calendar_month</mat-icon>
                </button>
              </div>
            </div>
            <mat-datepicker #startPicker></mat-datepicker>


            <span class="d-flex col-md-1 h-100 justify-content-center align-items-center">au</span>

            <div class="input-group">
              <input [matDatepicker]="endPicker"
                     autocomplete="off"
                     class="form-control"
                     [max]="maxDate"
                     [min]="ensembleForm.get('dateBegin').value"
                     formControlName="dateEnd">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary form-control" (click)="endPicker.open()">
                  <mat-icon>calendar_month</mat-icon>
                </button>
              </div>
            </div>
            <mat-datepicker #endPicker></mat-datepicker>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-4 d-flex">
            <button (click)="saveEnsemble()" class="btn btn-primary"
                    type="submit">{{edit ? "Sauvegarder" : "Ajouter"}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
