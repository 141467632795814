import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {TreeItemFlatNode} from '../../../../../model/event/treeData/tree-item-flat-node.model';
import {TreeItemNode} from '../../../../../model/event/treeData/tree-item-node.model';
import {MatDialogActions, MatDialogRef} from '@angular/material/dialog';
import {MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule} from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {TreeDataService} from '../../../../../services/tree-data.service';
import {FormatService} from '../../../../../services/format.service';
import {EnsembleWebservice} from '../../../../../services/webservices/ensemble.webservice';
import {PaginationService} from '../../../../../services/pagination.service';
import {Destroyed} from '../../../directives/destroyed.directive';
import {PaginationComponent} from '../../../pagination/pagination.component';
import {MatIconModule} from '@angular/material/icon';
import {NgIf} from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {Paged} from 'src/app/model/response/paged.model';

@Component({
  selector: 'app-set-attribution',
  templateUrl: './set-attribution.component.html',
  styleUrls: ['./set-attribution.component.scss'],
  providers: [TreeDataService, PaginationService],
  standalone: true,
  imports: [
    MatTreeModule,
    MatButtonModule,
    MatCheckboxModule,
    NgIf,
    MatIconModule,
    PaginationComponent,
    MatDialogActions
  ]
})
export class SetAttributionComponent extends Destroyed implements OnInit {
  @Input() selectedProject;
  @Output() validate = new EventEmitter<number>();
  subscription: Subscription[] = [];
  isLoading = true;

  itemPerPage = 10;

  selectedNode: TreeItemFlatNode;
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TreeItemFlatNode, TreeItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TreeItemNode, TreeItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TreeItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TreeItemFlatNode>;

  treeFlattener: MatTreeFlattener<TreeItemNode, TreeItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TreeItemNode, TreeItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TreeItemFlatNode>(true /* multiple */);

  constructor(
    private readonly treeDataService: TreeDataService,
    private readonly formatService: FormatService,
    private readonly dialogRef: MatDialogRef<SetAttributionComponent>,
    private readonly ensembleService: EnsembleWebservice,
    private readonly paginationService: PaginationService
  ) {
    super();
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TreeItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnInit() {
    this.paginationService.initPagination(this.itemPerPage);
    this.ensembleService
      .getEnsembleTree(this.paginationService.pageRequest, false)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: (data: Paged<TreeItemFlatNode>) => {
          this.paginationService.setPage(1);
          this.paginationService.setPagination(data.count);
          this.dataSource.data = data.items;
          if (this.selectedProject) {
            this.selectedNode = this.treeControl.dataNodes.find(
              (res) => res.id === this.selectedProject
            );
            if (this.selectedNode) {
              this.checklistSelection.select(this.selectedNode);
            }
            this.disableOtherCheckbox(this.selectedNode);
          }
        }
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  updateList() {
    this.ensembleService
      .getEnsembleTree(this.paginationService.pageRequest, false)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: (data: Paged<TreeItemFlatNode>) => {
          this.dataSource.data = data.items;
          if (this.selectedProject) {
            this.selectedNode = this.treeControl.dataNodes.find(
              (res) => res.id === this.selectedProject
            );
            if (this.selectedNode) {
              this.checklistSelection.select(this.selectedNode);
            }
            this.disableOtherCheckbox(this.selectedNode);
          }
        }
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  refreshList() {
    const sub = this.treeDataService.allEnsembleMessagerAttribution.subscribe(
      (data) => {},
      (err) => {},
      () => {
        sub.unsubscribe();
      }
    );
  }

  getLevel = (node: TreeItemFlatNode) => node.level;

  isExpandable = (node: TreeItemFlatNode) => node.expandable;

  getChildren = (node: TreeItemNode): TreeItemNode[] => node.listOfChilds;

  hasChild = (_: number, _nodeData: TreeItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TreeItemFlatNode) => _nodeData.name === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TreeItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.name === node.name ? existingNode : new TreeItemFlatNode();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.expandable = !!node.listOfChilds;
    flatNode.id = node.id;
    flatNode.badge = node.badge;
    flatNode.niveau = node.level;
    flatNode.thematique = node.thematique;
    flatNode.date = node.date;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TreeItemFlatNode): boolean {
    if (node && this.treeControl.dataNodes) {
      const descendants = this.treeControl.getDescendants(node);
      const descAllSelected = descendants.every((child) =>
        this.checklistSelection.isSelected(child)
      );
      return descAllSelected;
    } else {
      return false;
    }
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TreeItemFlatNode): void {
    if (node) {
      this.checklistSelection.toggle(node);
      if (this.checklistSelection.selected.length === 0) {
        this.selectedProject = null;
      } else {
        this.selectedProject = node.id;
      }
      this.disableOtherCheckbox(node);
    }
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TreeItemFlatNode): void {
    this.checklistSelection.toggle(node);
    if (this.checklistSelection.selected.length === 0) {
      this.selectedProject = null;
    } else {
      this.selectedProject = node.id;
    }
    this.checkAllParentsSelection(node);
    this.disableOtherCheckbox(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TreeItemFlatNode): void {
    let parent: TreeItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TreeItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TreeItemFlatNode): TreeItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  disableOtherCheckbox(node: TreeItemFlatNode) {
    const filteredList = this.treeControl.dataNodes.filter(
      (res) => !this.checklistSelection.isSelected(res)
    );
    let parent;
    if (node) {
      if (this.getLevel(node)) {
        parent = this.getParentNode(node);
        const nodeParent = this.treeControl.dataNodes.find((res) => res.id === parent.id);
        nodeParent.disabled = true;
        this.checklistSelection.deselect(nodeParent);
      }
      if (filteredList.length !== this.treeControl.dataNodes.length) {
        filteredList.forEach((item) => {
          item.disabled = true;
        });
      } else {
        filteredList.forEach((item) => {
          item.disabled = false;
        });
      }
    } else {
      filteredList.forEach((item) => {
        item.disabled = true;
      });
    }
  }

  validSelection() {
    if (this.checklistSelection.selected.length > 1) {
      const value = this.checklistSelection.selected.filter((res) => res.level === 0)[0];
      if (value) {
        this.validate.emit(value.id);
      }
    } else {
      if (this.checklistSelection.selected[0]) {
        this.validate.emit(this.checklistSelection.selected[0].id);
      }
    }
    if (this.checklistSelection.selected.length === 0) {
      this.validate.emit(null);
    }
  }
}
