<div class="d-flex flex-column h-100">
  <div class="ensemble-tree">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodeToggle>
        <button disabled mat-icon-button></button>
        <mat-checkbox (change)="todoLeafItemSelectionToggle(node)"
                      [checked]="checklistSelection.isSelected(node)"
                      [disabled]="node.disabled"
                      class="checklist-leaf-node"
                      color="primary">{{node.name}}
        </mat-checkbox>
        <span *ngIf="node.badge" [style.background-color]="node.badge.backgroundColor" [style.color]="node.badge.color"
              class="badge ml-2">
                        {{ node.badge.text }}
        </span>
        <span *ngIf="node.thematique" [style.background-color]="node.thematique.backgroundColor"
              [style.color]="node.thematique.color"
              class="badge ml-2">
                        {{ node.thematique.text }}
        </span>
        <span *ngIf="node.date" [style.background-color]="node.date.backgroundColor" [style.color]="node.date.color"
              class="badge ml-2">
                        {{ node.date.text1 }} - {{ node.date.text2 }}
        </span>
      </mat-tree-node>


      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button [attr.aria-label]="'Toggle ' + node.item" mat-icon-button
                matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-checkbox
          (change)="todoItemSelectionToggle(node)"
          [checked]="checklistSelection.isSelected(node)"
          [disabled]="node.disabled"
          color="primary">{{node.name}}
        </mat-checkbox>
        <span *ngIf="node.badge" [style.background-color]="node.badge.backgroundColor" [style.color]="node.badge.color"
              class="badge ml-2">
                        {{ node.badge.text }}
        </span>
        <span *ngIf="node.thematique" [style.background-color]="node.thematique.backgroundColor"
              [style.color]="node.thematique.color"
              class="badge ml-2">
                        {{ node.thematique.text }}
        </span>
        <span *ngIf="node.date" [style.background-color]="node.date.backgroundColor" [style.color]="node.date.color"
              class="badge ml-2">
                        {{ node.date.text1 }} - {{ node.date.text2 }}
        </span>
      </mat-tree-node>
    </mat-tree>
  </div>

  <div class="d-flex justify-content-end pagination-border">
    <app-pagination (pageChange)="updateList()" *ngIf="!isLoading"
                    [itemPerPage]="itemPerPage"></app-pagination>
  </div>

  <div class="d-flex justify-content-center" mat-dialog-actions >
    <button (click)="validSelection()" class="btn btn-outline-primary">Valider</button>
  </div>
</div>
